@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  letter-spacing: 0.03em;
}

html,
body {
  font-family: -apple-system, BlinkMacSystemFont, "Noto Sans JP", "Segoe UI",
    "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans",
    "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  margin: 0;
  padding: 0;
  background: white;
  box-sizing: border-box;
  text-rendering: optimizeLegibility;
  touch-action: manipulation;
  text-size-adjust: none;
  user-select: none;
}

code {
  font-family: source-code-pro, "Noto Sans JP", Menlo, Monaco, Consolas,
    "Courier New", monospace;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-shadow {
  text-shadow: 1px 0 0 #fff, -1px 0 0 #fff, 0 1px 0 #fff, 0 -1px 0 #fff,
    1px 1px #fff, -1px -1px 0 #fff, 1px -1px 0 #fff, -1px 1px 0 #fff;
}

:root {
  --primary: #fff;
  --secondary: #000000;
  --main-black: #212733;
  --red: #fe7284;

  --box-shadow: rgba(19, 95, 105, 0.12);
  --box-shadow-1: rgba(0, 0, 0, 0.2);
}
